<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Часовые тарифные ставки труда</h2>
      <div>
        <UIButton
          class="add-btn"
          text="Добавить"
          leftIcon="bi-plus"
          @click="addLaborData"
        />
      </div>
    </div>
    <TableSlot v-bind="labors" @rowClick="showLaborDetails" />
  </div>
</template>

<script>
import { API_labor_list } from "@/services/references/labor";
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";

export default {
  name: "cULaborCodeList",
  components: { TableSlot, UIButton },
  data() {
    return {
      labors: {
        columns: [
          {
            name: "Наименование",
            path: "WorkIdentifiedObject.IdentifiedObject.name",
          },
          {
            name: "Код",
            path: "code",
          },
          {
            name: "Тариф",
            path: "enproRate.value",
          },
          {
            name: "Статус",
            path: "status.value",
          },
        ],
        data: [],
        filters: {
          "WorkIdentifiedObject.IdentifiedObject.name": "",
          code: "",
        },
      },
    };
  },
  mounted() {
    API_labor_list().then((res) => {
      this.labors.data = res;
    });
  },
  methods: {
    showLaborDetails(item) {
      this.$router.push({
        path: `/references/labor-code/${item.id}`,
      });
    },
    addLaborData() {
      this.$router.push({
        path: `/references/labor-code/add`,
      });
    },
  },
};
</script>

<style scoped></style>
